import * as R from 'ramda'
import React from 'react'
import { graphql, navigate } from 'gatsby'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import { useNavigate } from '@reach/router'
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import BlockContent from '../components/BlockContent'
import { mapIndexed, nilOrEmpty, notNilOrEmpty } from '../lib/Helpers'
import SEO from '../components/SEO'
import Hero from '../components/Hero'
import Layout from '../components/Layout'
import ExhibitorsGrid from '../components/ExhibitorsGrid'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: 50
  },
  heading: {
    fontSize: theme.typography.pxToRem(20),
    color: '#393939',
    fontWeight: 900,
    fontFamily: 'Montserrat'
  },
  subheading: {
    fontSize: theme.typography.pxToRem(18),
    color: '#393939',
    fontWeight: 400,
    fontFamily: 'Montserrat'
  }
}))

export default props => {
  const { data } = props
  const { edges: companies } = data.allSanityExhibitors
  const { edges: challengeType } = data.allSanityVendorType
  const { sanityExhibitorsPage: content } = data
  const classes = useStyles()

  const [defaultOrder, setDefaultOrder] = React.useState([])
  const [selectedCategory, setSelectedCategory] = React.useState('')
  const [selectedChallengeType, setSelectedChallengeType] = React.useState()
  const [currentChallengeAssets, setCurrentChallengeAssets] = React.useState({})

  const navigate = useNavigate()

  return (
    <Layout>
      <SEO
        title={`${content.pageTitle} | Afwerx Engage Space Virtual Showcase`}
        keywords={content.seo.keywords}
        description={content.seo.description}
        author={content.seo.author}
        image={content.seo.seo_image.asset.url}
        url={`https://engage.space/${content.slug.current}`}
      />
      <Hero
        title={content.pageTitle}
        className="challenge-type"
        img={content.hero_image.asset.url}
      />
      {notNilOrEmpty(content._rawBodyContent) && (
        <section className="container intro-text page--exhibitors">
          <BlockContent blocks={content._rawBodyContent} />
          <div className="container" style={{ margin: '0 auto 3rem' }}>
            <Accordion className="accordion-container container">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>Learn More</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <BlockContent blocks={content._rawBodyContentLearnMore} />
              </AccordionDetails>
            </Accordion>
          </div>
        </section>
      )}
      <div className="challenges-grid container">
        <div
          className="challenges-grid__single"
          style={{
            backgroundImage: `url(${require('../assets/imgs/bg_dod.jpg')})`
          }}
        >
          <img
            src={require('../assets/imgs/space_DOD.svg')}
            alt="DoD Challenge Logo"
          />
        </div>
        <div
          className="challenges-grid__single"
          style={{
            backgroundImage: `url(${require('../assets/imgs/bg_transport.jpg')})`
          }}
        >
          <img
            src={require('../assets/imgs/space_Transport.svg')}
            alt="DoD Challenge Logo"
          />
        </div>
        <div
          className="challenges-grid__single"
          style={{
            backgroundImage: `url(${require('../assets/imgs/bg_persistant.jpg')})`
          }}
        >
          <img
            src={require('../assets/imgs/space_Persistent_ISR.svg')}
            alt="DoD Challenge Logo"
          />
        </div>
        <div
          className="challenges-grid__single"
          style={{
            backgroundImage: `url(${require('../assets/imgs/bg_asset.jpg')})`
          }}
        >
          <img
            src={require('../assets/imgs/space_Space_Asset.svg')}
            alt="DoD Challenge Logo"
          />
        </div>
      </div>
      <ExhibitorsGrid />
    </Layout>
  )
}

export const catQuery = graphql`
  query {
    sanityExhibitorsPage {
      _rawBodyContent
      _rawBodyContentLearnMore
      pageTitle
      slug {
        current
      }
      hero_image {
        asset {
          url
        }
      }
      seo {
        author
        description
        keywords
        seo_image {
          asset {
            url
          }
        }
      }
    }
    allSanityVendorType {
      edges {
        node {
          title
          categories {
            slug {
              current
            }
            title
          }
        }
      }
    }
    allSanityExhibitors {
      edges {
        node {
          id
          title
          slug {
            current
          }
          company_img {
            asset {
              fluid {
                ...GatsbySanityImageFluid
              }
            }
          }
          categories {
            slug {
              current
            }
            title
          }
          challengeType {
            title
            categories {
              slug {
                current
              }
              title
            }
            challenge_hero_overlay_image {
              asset {
                fluid {
                  ...GatsbySanityImageFluid
                }
              }
            }
            challenge_hero_background {
              asset {
                fluid {
                  ...GatsbySanityImageFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
