import * as R from 'ramda'
import React from 'react'
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import algoliasearch from 'algoliasearch/lite'
import {
  ClearRefinements,
  Configure,
  CurrentRefinements,
  RefinementList,
  Highlight,
  Hits,
  InstantSearch,
  connectHits,
  connectHighlight,
  connectInfiniteHits,
  connectMenu,
  connectSearchBox,
  connectSortBy,
  connectRefinementList
} from 'react-instantsearch-dom'

import { mapIndexed, nilOrEmpty, notNilOrEmpty } from '../lib/Helpers'

const searchClient = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID,
  process.env.GATSBY_ALGOLIA_SEARCH_KEY
)

export default ({ selectedCategory, selectedChallengeType, ...props }) => {
  const [selectedChallenge, setSelectedChallenge] = React.useState(
    selectedChallengeType ? selectedChallengeType : ''
  )

  const Hit = ({ hits, hasPrevious, refinePrevious, hasMore, refineNext }) => {
    return (
      <>
        <section className="exhibitors">
          {mapIndexed((hit, index) => (
            <AniLink
              key={index}
              cover
              duration={1}
              className=""
              to={`/exhibitor/${hit.slug}`}
              bg="#000"
            >
              <div className={`exhibitors__single animated`} key={index}>
                <div className="company_image">
                  {notNilOrEmpty(hit.company_img) && (
                    <img src={hit.company_img.asset.url} />
                  )}
                </div>
                <div className="company_data">
                  <p className="company_data__title">
                    <Highlight attribute="title" hit={hit} />
                  </p>
                  <p className="company_data__desc">
                    <Highlight attribute="solution_name" hit={hit} />
                  </p>
                  {hit._snippetResult.description.matchLevel !== 'none' && (
                    <CustomHighlight attribute="description" hit={hit} />
                  )}
                </div>
              </div>
            </AniLink>
          ))(hits)}
        </section>
        {hasMore && (
          <div className="show-more-btn-container">
            <button className="btn btn--yellow" onClick={refineNext}>
              Show more
            </button>
          </div>
        )}
      </>
    )
  }

  const DescHighlight = props => {
    const { highlight, attribute, hit } = props
    const parsedHit = highlight({
      highlightProperty: '_snippetResult',
      attribute,
      hit
    })

    return (
      <>
        <hr />
        <small>
          Contains:{' '}
          {parsedHit.map((part, index) =>
            part.isHighlighted ? <mark key={index}> {part.value} </mark> : null
          )}
        </small>
      </>
    )
  }

  const SearchBox = ({ currentRefinement, refine }) => {
    return (
      <TextField
        type="search"
        label="Search"
        variant="outlined"
        value={currentRefinement}
        onChange={event => refine(event.currentTarget.value)}
      />
    )
  }

  const ChallengeSelect = ({ items, currentRefinement, refine }) => {
    return (
      <FormControl variant="outlined" className="filter-dropdown">
        <InputLabel id="challenge-label">Challenge</InputLabel>
        <Select
          label="Challenge"
          labelId="challenge-label"
          id="challenge-select"
          value={currentRefinement || ''}
          onChange={event => {
            refine(event.target.value)
          }}
        >
          <MenuItem value=""> - ALL - </MenuItem>
          {mapIndexed((item, index) => {
            return (
              <MenuItem key={index} value={item.label}>
                {R.toUpper(item.label)}
              </MenuItem>
            )
          })(R.compose(R.sort(R.ascend(R.path(['label']))))(items))}
        </Select>
      </FormControl>
    )
  }

  const CategorySelect = ({ items, currentRefinement, refine }) => {
    return (
      <FormControl variant="outlined" className="filter-dropdown">
        <InputLabel id="cats-label">Categories</InputLabel>
        <Select
          label="Categories"
          labelId="cats-label"
          id="cats-select"
          value={currentRefinement || ''}
          onChange={event => {
            refine(event.target.value)
          }}
        >
          <MenuItem value=""> - ALL - </MenuItem>
          {mapIndexed((item, index) => {
            return (
              <MenuItem key={index} value={item.label}>
                {R.toUpper(item.label)}
              </MenuItem>
            )
          })(R.compose(R.sort(R.ascend(R.path(['label']))))(items))}
        </Select>
      </FormControl>
    )
  }

  const SortBy = props => {
    const { items, refine, createURL } = props
    return (
      <FormControl variant="outlined" className="filter-dropdown">
        <InputLabel id="sort-label">Sort</InputLabel>
        <Select
          label="Sort"
          labelId="sort-label"
          id="sort-select"
          defaultValue={props.defaultSort.value}
          onChange={e => refine(e.target.value)}
        >
          {mapIndexed((item, index) => (
            <MenuItem key={index} value={item.value}>
              {item.label}
            </MenuItem>
          ))(items)}
        </Select>
      </FormControl>
    )
  }

  const CustomCategorySelect = connectMenu(CategorySelect)
  const CustomChallengeSelect = connectMenu(ChallengeSelect)
  const CustomSearchBox = connectSearchBox(SearchBox)
  const ExhibitorHit = connectInfiniteHits(Hit)
  const CustomSortBy = connectSortBy(SortBy)
  const CustomHighlight = connectHighlight(DescHighlight)

  return (
    <div className="container">
      <InstantSearch searchClient={searchClient} indexName="Exhibitors">
        <Configure
          distinct
          attributesToSnippet={['description:10']}
          snippetEllipsisText="…"
          removeWordsIfNoResults="allOptional"
          hitsPerPage={21}
        />
        <section className="filters">
          <CustomSortBy
            defaultRefinement="Exhibitors"
            currentRefinement="Exhibitors"
            defaultSort={{ value: 'Exhibitors', label: 'A - Z' }}
            items={[
              { value: 'Exhibitors', label: 'A - Z' },
              { value: 'Exhibitors_DESC', label: 'Z - A' }
            ]}
          />
          <CustomChallengeSelect
            attribute="challenge"
            defaultRefinement={selectedChallenge}
          />
          <CustomCategorySelect
            attribute="categories.title"
            defaultRefinement={selectedCategory ? selectedCategory : ''}
          />
          <CustomSearchBox />
        </section>
        <ExhibitorHit />
      </InstantSearch>
    </div>
  )
}
